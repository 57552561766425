@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    font-size: 16px;
    @apply z-0 m-0 p-0 font-sans;
    overflow-x: hidden;
  }

  img.--rectangle {
    width: auto;
    height: 100%;
    object-fit: cover;
  }

  /* a {
    @apply hover:underline underline-offset-4 cursor-pointer;
  } */

  .--page {
    @apply flex flex-col w-screen h-screen;
  }
}

@layer components {
  .--img-container-square {
    @apply flex justify-center items-center overflow-hidden aspect-square;
  }
}

@layer utilities {
}
#disqus_thread { color-scheme: none; }